export const address = [
  'Thomas Ehleringer',
  'Lindenstr. 1',
  '54597 Seiwerath',
  'Tel.: 06553/3177',
  'Fax: 06553/961073',
  'E-Mail: Thomas@Ehleringer.de',
];

export const jobTitle =
  'Von der Landwirtschaftkammer Rheinland-Pfalz öffentlich bestellter und vereidigter Sachverständiger';
